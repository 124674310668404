import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, FormControl, InputLabel, MenuItem, Select, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import RegionEntity, { RegionType } from "../models/layout/RegionEntity";

import { Color3, Quaternion } from "@babylonjs/core";
import ColorPicker from "./ColorPicker";
import Color3Model from "../models/layout/Color3Model";
import Vector3Model from "../models/layout/Vector3Model";
import QuaternionModel from "../models/layout/QuaternionModel";
import PoseModel from "../models/layout/PoseModel";

function createRegularPolygonPoints(n: number, radius: number): Vector3Model[] {
  if (n < 3) {
      throw new Error("A polygon must have at least 3 points.");
  }

  const points: Vector3Model[] = [];
  const angleStep = (2 * Math.PI) / n; // Angle between consecutive points

  for (let i = 0; i < n; i++) {
      const angle = i * angleStep; // Current angle in radians
      const x = radius * Math.cos(angle); // X-coordinate
      const z = radius * Math.sin(angle); // Z-coordinate
      points.push(new Vector3Model({x: x, y: 0, z: z})); // Assuming Y is 0 for a flat polygon
  }

  return points;
}

const RegionProperties: FunctionComponent<{ region: RegionEntity }> = observer(({ region }) => {

  const predefinedColors = ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF"];

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Region Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <TextField
              label="Name"
              value={region.name}
              onChange={(e) => {region.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            <ColorPicker
              colors={predefinedColors}
              value={region.color?.asHex ?? "#FF0000"} 
              onChange={(color) => {region.setColor(Color3Model.fromColor3(Color3.FromHexString(color)))}} 
            />
            <FormControl fullWidth sx={{marginTop: 2}}>
              <InputLabel id="region-type-list-label">Region Type</InputLabel>
              <Select
                labelId="region-type-list-label"
                id="region-type-list"
                fullWidth
                value={region.regionType?.toString() ?? ""}
                label="Region Type"            
                onChange={(event)=>{
                  region.setRegionType(parseInt(event.target.value));
                  if (region.regionType === RegionType.Polygonal && region.polygonPoints.length < 3) {
                    region.setPolygonPoints(createRegularPolygonPoints(3, 1));
                  }
                }}
                >
                  <MenuItem value={RegionType.Circular.toString()}>Circular</MenuItem>
                  <MenuItem value={RegionType.Rectangular.toString()}>Rectagle</MenuItem>
                  <MenuItem value={RegionType.Polygonal.toString()}>Polygon</MenuItem>
              </Select>
            </FormControl>
            
            {region.regionType === RegionType.Circular &&
              <>
                <InputLabel id="region-radius" sx={{marginTop: 2}}>Radius</InputLabel>
                <Slider
                  size="small"
                  value={region.circleRadius}
                  min={0.0}
                  max={10}
                  step={0.01}
                  valueLabelDisplay="auto"
                  onChange={(e, v) => {region.setCircleRadius(v as number)}}
                />
              </>
            }

            {region.regionType === RegionType.Rectangular &&
              <>
                <TextField
                    label="Width"
                    value={region.rectangleWidth}
                    type="number"
                    inputProps={{step: 0.01}}
                    fullWidth
                    margin="normal"
                    onChange={(e) => {region.setRectangleWidth(parseFloat(e.target.value))}}
                  />
                <TextField
                    label="Height"
                    value={region.rectangleHeight}
                    type="number"
                    inputProps={{step: 0.01}}
                    fullWidth
                    margin="normal"
                    onChange={(e) => {region.setRectangleHeight(parseFloat(e.target.value))}}
                  />
              </>
            }

            {region.regionType === RegionType.Polygonal &&
              <>
                <InputLabel id="region-count-points" sx={{marginTop: 2}}>Count Points</InputLabel>
                <Slider
                  size="small"
                  value={region.polygonPoints.length}
                  min={3}
                  max={20}
                  step={1}
                  valueLabelDisplay="auto"
                  onChange={(e, v) => {region.setPolygonPoints(createRegularPolygonPoints(v as number, 1))}}/>
              </>
            }

            <InputLabel id="region-rotation" sx={{marginTop: 2}}>Rotation</InputLabel>
            <Slider
              size="small"
              value={region.rotation}
              min={-360}
              max={360}
              step={1}
              valueLabelDisplay="auto"
              onChange={(e, v) => {
                region.setRotation(v as number);
              }}
            />

            {/*  */}
            {/* <TextField
              label="Position X"
              value={region.pose.position.x}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              value={region.pose.position.y}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              value={region.pose.position.z}
              type="number"
              fullWidth
              margin="normal"
            /> */}
            {/* <TextField
              label="Position X"
              value={camera.position?.x}
              type="number"
              onChange={(e) => {camera.position.setX(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              type="number"
              value={camera.position?.y}
              onChange={(e) => {camera.position.setY(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              type="number"
              value={camera.position?.z}
              onChange={(e) => {camera.position.setZ(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            /> */}
          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default RegionProperties;