import { FunctionComponent, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Stack, Typography } from '@mui/material';
import Site from "../models/Site";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ShiftChart from "../charts/ShiftChart";
import WeekPicker from "../components/WeekPicker";
import useQueryState from "../hooks/useQueryState";
import PageState from "../models/PageState";
import OccupancyChart from "./OccupancyChart";

const ShiftContainer: React.FC<{ pageState: PageState, site: Site }> = observer(({ pageState, site }) => {
  //const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('week'));
  const [startDate, setStartDate] = useQueryState<Dayjs>("d2" + site.siteId, pageState, "wd", dayjs(), (value) => value.toISOString(), (value) => dayjs(value)); 
  const [hasData, setHasData] = useState<boolean>(false);

  const handleRefresh = () => {
    setHasData(true);
    setStartDate(startDate, true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <Box component="div" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      
          <Box component="div" gap={2} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', margin: '10px'}}>
            <Typography variant="h4">Staffing</Typography>
            <WeekPicker
              weekLabel="Date"
              initialStartDate={startDate}
              useMonday={site.dayOfWeekStart === 1}

              onChange={(date) => {
                setHasData(false);
                setStartDate(date, false);
              }}
            />
            <Button id="refreshChart" onClick={handleRefresh} size="small">Refresh</Button>
          </Box>

        {hasData ? (
          <Stack>
            <ShiftChart date={startDate} site={site} />
            <OccupancyChart date={startDate} site={site} />
          </Stack>
        ) : (
          <Box component="div" sx={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px' }}>
            <Typography>Click refresh to render chart</Typography>
          </Box>
        )}

      </Box>
    </LocalizationProvider>
  );  
});

export default ShiftContainer;
