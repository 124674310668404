import React, { ReactNode, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs, ManipulateType } from "dayjs";

// Define the possible types for granularity
type Granularity = 'small' | 'large' | 'medium' | 'fixed';

// Define the shape of the props for the component
interface WeekPickerProps {

  onChange?: null | ((from: Dayjs) => void);

  weekLabel?: string;
  
  useMonday?: boolean;

  initialStartDate: Dayjs;

  sx?: object; // Add sx prop
}

const WeekPicker: React.FC<WeekPickerProps> = ({
  weekLabel = "Week",
  useMonday = false,
  initialStartDate,  
  onChange = null,
  sx
}) => {

  // State hooks to manage user input
  const [startDate, setStartDate] = useState<Dayjs>(initialStartDate);
  
  const changeStartDate = (date: Dayjs | null) => {
    if (date === null) return;
    const adjustedDate = useMonday ? date.startOf('week').add(1, "day") : date.startOf("week");
    setStartDate(adjustedDate);    
    if (onChange) {
      onChange(adjustedDate);
    }
  }

 
  // Render DateTimePicker component
  const renderDatePicker = (
    label: string,
    value: Dayjs | null,
    onChange: (date: Dayjs | null) => void
  ) => {
    return (
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
      />
    );    
  };  

  return (
    <>
      <Box component="div" display="flex" flexDirection="row" gap={2} sx={sx}>
        {renderDatePicker(weekLabel, startDate, changeStartDate)}        
      </Box>
    </>
  );
};

export default WeekPicker;
