import { Color3, Vector3 } from "@babylonjs/core";
import Tracklet, { TrackletType } from "./Tracklet";
import Site from "./Site";
import TrackedObjectDataPoint from "./TrackedObjectDataPoint";
import { decodeBase64ToFloatArray, decodeBase64ToShortArray, decodeBase64ToUShortArray } from "../util/JsonUtil";


export enum TrackedObjectType { Person, Vehicle }

export type TimeSpanDictionary = {
    [key: string]: number; // The value is the number of seconds representing the time span
};

export default class TrackedObject {

    site: Site;
    trackedObjectId: string;
    trackedObjectType: TrackedObjectType;
    startTime: Date;
    endTime: Date;
    startsAtEntrance?: string;
    endsAtEntrance?: string;
    isTeamMember: boolean = false; //todo: less RRS specific way to handle this
    isStationary: boolean = false;
    isShortLived: boolean = false;
    color: Color3;
    tracklets: Tracklet[];
    fusedTracklet?: Tracklet;
    dataPoints: TrackedObjectDataPoint[] = [];
    timeEngagedWithTags: TimeSpanDictionary = {};


    constructor(site: Site, trackedObjectId: string, trackedObjectType: TrackedObjectType, startTime: Date, endTime: Date, isStationary: boolean, color: Color3, tracklets: Tracklet[], timeEngagedWithTags: TimeSpanDictionary) {
        this.site = site;
        this.trackedObjectId = trackedObjectId;
        this.trackedObjectType = trackedObjectType;
        this.startTime = startTime;
        this.endTime = endTime;
        this.isStationary = isStationary
        this.isShortLived = (endTime.getTime() - startTime.getTime()) < 10000;
        this.color = color;
        this.timeEngagedWithTags = timeEngagedWithTags;
        this.tracklets = tracklets.filter(t => t.trackletType === TrackletType.CameraDetection);
        tracklets.forEach(tracklet => {
            tracklet.setTrackedObject(this);
            if (tracklet.trackletType === TrackletType.Fused) {
                this.fusedTracklet = tracklet;
            } else {
                if (tracklet.startsAtEntrance && (Math.abs(tracklet.startTime.getTime() - this.startTime.getTime()) < 4000)) {
                    this.startsAtEntrance = tracklet.startsAtEntrance;
                }
                if (tracklet.endsAtEntrance && (Math.abs(tracklet.endTime.getTime() - this.endTime.getTime()) < 4000)) {
                    this.endsAtEntrance = tracklet.endsAtEntrance;
                }
            }
            //todo: this is a hack
            if (this.isStationary) {
                this.color = Color3.Teal();
            } else if (this.startsAtEntrance === "BackOfHouse" || this.endsAtEntrance === "BackOfHouse") {
                this.isTeamMember = true;
                this.color = Color3.Green();
            } else if (this.startsAtEntrance === undefined && this.endsAtEntrance === undefined) {
                this.color = Color3.Gray();
            } else {
                this.color = Color3.Red();
            }
        });
        
    }

    overlapsTrackedObject (trackedObject: TrackedObject): boolean {
        return !(trackedObject.startTime > this.endTime || trackedObject.endTime < this.startTime);
      }

    overlapsTracklet (tracklet: Tracklet): boolean {
        return !(tracklet.startTime > this.endTime || tracklet.endTime < this.startTime);
      }

    findClosestDataPoint (currentTime: Date): TrackedObjectDataPoint | undefined {
        if (currentTime < this.startTime || currentTime > this.endTime) 
            return undefined;

        let closestDataPoint : TrackedObjectDataPoint | undefined = undefined;
        let minTimeDiff = Infinity;
        
        this.dataPoints.forEach(dp => {
            const timeDiff = Math.abs(currentTime.getTime() - dp.time.getTime());
            if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            closestDataPoint = dp;
            }
        });
        
        return closestDataPoint;
    }

    static AddTrackedObjectDataPointsFromBuckets(buckets: any[], trackedObjectMap: Record<string, TrackedObject>) {
        buckets.forEach((bucket, index) => {
            const trackedObjectId = bucket.trackedObjectId;
            const trackedObject = trackedObjectMap[trackedObjectId];
            if (trackedObject) {
                const startTime = new Date(bucket.startTime + 'Z').toUtc();
                const posXArray = decodeBase64ToFloatArray(bucket.posX);
                const posYArray = decodeBase64ToFloatArray(bucket.posY);
                const posZArray = decodeBase64ToFloatArray(bucket.posZ);
                const timeArray = decodeBase64ToUShortArray(bucket.timeOffset).map(timeOffset => new Date(startTime.getTime() + 10 * timeOffset));

                for (let i = 0; i < timeArray.length; i++) {
                    trackedObject.dataPoints.push(new TrackedObjectDataPoint(timeArray[i], new Vector3(posXArray[i], posYArray[i], posZArray[i])));
                }
            }
        });
    }
}