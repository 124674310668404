import { int } from "@babylonjs/core";

export class DwellTimeBucket {

    startInterval: int;
    endInterval: int;
    count: int;

    constructor(startInterval: int, endInterval: int, count: int) {
        this.startInterval = startInterval;
        this.endInterval = endInterval;
        this.count = count;
    }
}

export class DwellTimeSummary {
  regionId: string;
  totalDwellTime: number;
  averageDwellTime: number;

  constructor(regionId: string, totalDwellTime: number, averageDwellTime: number) {
    this.regionId = regionId;
    this.totalDwellTime = totalDwellTime;
    this.averageDwellTime = averageDwellTime;
  }
}

export class DwellTimeDetail {
  regionId: string;
  date: Date;
  totalDwellTime: number;
  engagedCount: number;
  averageDwellTime: number;

  constructor(regionId: string, date: Date, totalDwellTime: number, engagedCount: number, averageDwellTime: number) {
    this.regionId = regionId;
    this.date = date;
    this.totalDwellTime = totalDwellTime;
    this.engagedCount = engagedCount;
    this.averageDwellTime = averageDwellTime;
  }
}


