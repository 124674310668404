import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import PageState from "../models/PageState";

function useQueryState<T>(page: string, pageState: PageState, key: string, defaultValue: T, serialize: (value: T) => string, deserialize: (value: string) => T): [T, (value: T, save?: boolean, replace?: boolean) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = searchParams.has(key) ? deserialize(searchParams.get(key)!) : null;
  const navigatorValue = pageState.getPageState(page, key, null);
  const initialValue = queryValue ?? navigatorValue ?? defaultValue;

  const [state, setState] = useState<T>(initialValue); 

  const setQueryState = (value: T, save: boolean = false, replaceQueryString: boolean = false) => {
    setState(value);
    if (save) {
      pageState.setPageState(page, key, value);
      const newParams = replaceQueryString ? new URLSearchParams() : new URLSearchParams(window.location.search);
      newParams.set(key, serialize(value));
      setSearchParams(newParams);
    }
  };

  return [state, setQueryState];
}

export default useQueryState;