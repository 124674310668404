import { Color3, Vector3 } from "@babylonjs/core";
import { DataModel, ExtendedModel, idProp, Model, model, modelAction, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import PoseModel from "./PoseModel";
import Color3Model from "./Color3Model";

export enum RegionType { Circular, Rectangular, Polygonal }

@model("SiteBionics/RegionEntity")
export default class RegionEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    color: prop<Color3Model>().withSetter(),
    position: prop<Vector3Model>().withSetter(),
    rotation: prop<number>().withSetter(),
    regionType: prop<RegionType>().withSetter(),
    polygonPoints: prop<Vector3Model[]>(() => {return []}).withSetter(),
    circleRadius: prop<number>().withSetter(),
    rectangleWidth: prop<number>().withSetter(),
    rectangleHeight: prop<number>().withSetter(),
}) {

    @modelAction addPoint(point: Vector3Model) {
        this.polygonPoints.push(point);
      }
}
