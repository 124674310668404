import { FunctionComponent, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"
import { Color3, Color4, Quaternion } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import RegionEntity, { RegionType } from "../models/layout/RegionEntity";
import { ExtrudePolygon, ActionManager, AxisDragGizmo, ExecuteCodeAction, GizmoCoordinatesMode, Mesh, MeshBuilder, PositionGizmo, TransformNode, PointerDragBehavior, Polygon } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Vector3Model from "../models/layout/Vector3Model";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import earcut from 'earcut'
import QuaternionModel from "../models/layout/QuaternionModel";
import PoseModel from "../models/layout/PoseModel";

const RegionPointLayer: FunctionComponent<{point: Vector3Model, pointMoved: () => void}> = observer(({point, pointMoved}) => {
  const transformNodeRef = useRef<Mesh>(null);

  return (
    <>
      <transformNode name='transformNode' ref={transformNodeRef} position={new Vector3(-(point.x), point.y, point.z)}>
        <lines name="lines" points={[new Vector3(0, -1, 0), new Vector3(0, 4, 0)]} color={Color3.Red()} />
        <sphere name="sphere" diameter={0.1} position={new Vector3(0, 0, 0)}/>
        <pointerDragBehavior
                  dragPlaneNormal={new Vector3(0, 1, 0)}
                  useObjectOrientationForDragging={true}
                  // onDragStartObservable={(_ : any) => console.log('dragStart')}
                  onDragObservable={(e : any) => {
                    point.setX(-transformNodeRef.current!.position.x); 
                    point.setZ(transformNodeRef.current!.position.z);
                    pointMoved();
                  }}
                  onDragEndObservable={(e: any) => {
                     point.setX(-transformNodeRef.current!.position.x); 
                     point.setZ(transformNodeRef.current!.position.z);
                     pointMoved();
                  }}
                />
      </transformNode>
    </>
  )
})

const RegionLayer: FunctionComponent<{region: RegionEntity, layoutViewModel: ScanAreaLayoutViewModel}> = observer(({region, layoutViewModel}) => {
  const transformNodeRef = useRef<Mesh>(null);
  const [version, setVersion] = useState<number>(0);

  // this is to fixup legacy data where the region didn't have a position
  if (region.position === undefined) region.setPosition(Vector3Model.identity());

  let isSelected = layoutViewModel.selectedEntity === region;

  return (
    <>
      <transformNode name='transformNode' ref={transformNodeRef} position={region.position.asLHVector3} rotationQuaternion={Quaternion.FromEulerAngles(0, region.rotation*Math.PI/180, 0)}>
        {isSelected &&
          <positionGizmo thickness={1}
            onDragEndObservable={(e:any) => {
              region.setPosition(Vector3Model.fromVector3LH(transformNodeRef.current!.position));}
            }
          />
        }     

        {region.regionType === RegionType.Polygonal && isSelected &&
          <>
            {
              region.polygonPoints.map((point, _index) => (
                <RegionPointLayer point={point} pointMoved={() => {
                  setVersion(version+1);
                }}/>
              ))
            }
            <extrudePolygon key={`polygon-${version}-${region.color.asHex}-${region.polygonPoints.length}`} name="extrudePolygon" shape={region.polygonPoints.map((point) => { return new Vector3(-point.x, 0, point.z) })} depth={0.1} faceColors={[new Color4(region.color.r, region.color.g, region.color.b, 1)]} position={new Vector3(0, 0.05, 0)} earcutInjection={earcut} updatable={true}/> 
          </>
        }

        {region.regionType === RegionType.Rectangular  && isSelected &&
          <box name="box" scaling={new Vector3(region.rectangleWidth, 1, region.rectangleHeight)} size={1} >
            <standardMaterial name="material" alpha={isSelected ? 0.8 : 0.6} diffuseColor={region.color.asColor3} />
          </box>
        }
      </transformNode>
    </>
  )
})

const RegionsLayer: FunctionComponent<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
    <>
      <utilityLayerRenderer>
      {layoutViewModel.scanAreaLayout?.regions?.map((region, index) => (
        <RegionLayer key={`region-${index}`} region={region} layoutViewModel={layoutViewModel}/>
      ))}
      </utilityLayerRenderer>

    </>
  )
})

export default RegionsLayer;


