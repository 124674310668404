import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import TitleSimple from "../components/TitleSimple";
import CameraCarousel from "../components/CameraCarousel";
import { useLocation } from 'react-router-dom';

const SiteSnapshots: FunctionComponent = observer(() => {
  const [loading, setLoading] = useState(false);
  const site = useSite();
  const account = site.account;
  const breadcrumbs = useSitePageBreadcrumbs("Site Snapshots");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const indexParam = queryParams.get('index');
  const [initialImageIndex, setInitialImageIndex] = useState<number | null>(indexParam ? parseInt(indexParam, 10) : null);
  const startPaused = !!indexParam;

  useEffect(() => {
    if (!site.cameras) {
      setLoading(true);
      site.loadCamerasAsync();
    } else {
      setLoading(false);
      if (initialImageIndex !== null) {
        if (initialImageIndex < 0) setInitialImageIndex(0);
        if (initialImageIndex >= site.cameras.length) setInitialImageIndex(site.cameras.length - 1);
      }
    }
  }, [site.cameras, initialImageIndex]);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <TitleSimple title="Site Snapshots" sx={{ paddingLeft: "10pt", paddingRight: "10pt" }} />
      <Box component="div" sx={{ paddingX: '10pt', overflow: "auto" }}>
        {loading && <Typography>Loading...</Typography>}
        {!loading && site.cameras && site.cameras.length > 0 &&
          <CameraCarousel
            cameras={site.cameras!}
            accountId={account.accountId}
            siteId={site.siteId}
            initialImageIndex={initialImageIndex !== null ? initialImageIndex : 0}
            startPaused={startPaused}
          />
        }
        {!loading && (!site.cameras || site.cameras.length === 0) &&
          <Typography>No cameras</Typography>
        }
      </Box>
    </>
  );
});

export default SiteSnapshots;