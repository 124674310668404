import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Tooltip, Menu, MenuItem, Box, Button, Checkbox, Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { SiteBionicsApplication, useSiteBionicsApplication } from "../models/SiteBionicsApplication";

import PairHubDialog from "../dialogs/PairHubDialog";
import { HubRecordingRequest } from "../models/SiteBionicsService";
import ElapsedTime from "../components/EllapsedTime";
import Hub from "../models/Hub";
import { useSite } from "./SitePage";
import TitleToolbar from "../components/TitleToolbar";
import Confirm from "../components/Confirm";
import NameDialog from "../dialogs/NameDialog";
import useSiteHubStatus from "../hooks/useSiteHubStatus";
import { HubStatusResponse, HubStatusCamera } from "../models/HubStatusResponse";
import StatusLED from '../components/StatusLED';
import ReactGA from "react-ga4";

interface HubTableRowProps {
  hub: Hub,
  hubStatus: HubStatusResponse | null | undefined;
}

const HubTableRow: React.FC<HubTableRowProps> = ({ hub, hubStatus }) => {

  const siteNavigator = SiteBionicsApplication.getInstance().siteNavigator!;
  
  const healthColor = hubStatus === undefined ? "grey" : hubStatus === null ? "red" : hubStatus.getHubStatusHealth();
  
  return (
    <TableRow key={hub.hubId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={hub.hubId === siteNavigator.currentHub?.hubId}
          onClick={ () =>{siteNavigator.setCurrentHub(hub)}}
        />
      </TableCell>
      
      <TableCell scope="row"><Tooltip title={hub.hubId}><span>{hub.name ? hub.name : "[no name]"} </span></Tooltip></TableCell>    
      <TableCell scope="row"> <StatusLED color={healthColor} /> </TableCell>
      <TableCell scope="row"> {hubStatus ? hubStatus.status.cameras.length : ""} </TableCell>
      <TableCell scope="row"> {hubStatus?.getHubStatusAverageVideoFPS()?.toFixed(1) ?? "N/A"} </TableCell>
      <TableCell scope="row"> {hubStatus?.getHubStatusAverageInferenceFPS()?.toFixed(1) ?? "N/A"} </TableCell>
      <TableCell scope="row"> {hubStatus ? <ElapsedTime time={new Date(hubStatus.time)}/> : ""} </TableCell>
      <TableCell scope="row"> 
          <>
          {(hubStatus && hubStatus.status.runningSince) ? <ElapsedTime time={new Date(hubStatus.status.runningSince)}/> : "Offline"}
          </>
        </TableCell>
    </TableRow>
  );
};

interface HubsProps {
  hubStatuses: Record<string, HubStatusResponse | null> | null;
  
}
const Hubs : React.FC<HubsProps> = observer(( {hubStatuses} ) => {
    const siteBionicsApplcation = useSiteBionicsApplication();
    const siteNavigator = siteBionicsApplcation.siteNavigator!;

    const [busy, setBusy] = useState(false);

    const [renameHubDialogOpen, setRenameHubDialogOpen] = useState(false);
    const [unpairHubDialogOpen, setUnpairHubDialogOpen] = useState(false);
    
    const [pairHubDialogOpen, setPairHubDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const site = useSite();

    useEffect(() => {

      if (!site.hubs) {
        setLoading(true);
        site.loadHubsAsync().then(() => {
          setLoading(false);
        });
      }
      
      if (!siteNavigator.currentHub && site.hubs && site.hubs.length > 0) {
        siteNavigator.setCurrentHub(site.hubs[0]);
      }
    }, [site.hubs]);
  
    const handleUnpairClick = () => {
      setUnpairHubDialogOpen(true);
    };

    const handleUnpair = () => {
      setBusy(true);
      siteNavigator.currentHub?.deleteAsync().then(() => {
        setUnpairHubDialogOpen(false);
        setBusy(false);
      });
    }

    
    
    const handlePairHubClick = () => {
      setPairHubDialogOpen(true);
    };

    const handlePair = (hubId: string, pairingCode: string) => {
      setBusy(true);
      siteBionicsApplcation.service.pairHubAsync(siteNavigator.site, hubId, pairingCode).then(() => {
        setBusy(false);
        setPairHubDialogOpen(false);
      });
    };

    const handlePairHubClose = () => {
      setPairHubDialogOpen(false);
    };

    const handleRestartClick = () => {
      event("restart");
      setBusy(true);
      siteBionicsApplcation.service.restartHubAsync(siteNavigator.currentHub!).then(() => {
        setBusy(false);
      })
    };

    const handleStartRecordingClick = () => {
      event("recording", "start", {tracking: true});

      setBusy(true);
      siteBionicsApplcation.service.putHubRecordingRequestAsync(siteNavigator.currentHub!, new HubRecordingRequest(true, true)).then(() => {
        setBusy(false);
      });
    };

    const handleStartRecordingNoTrackingClick = () => {
      event("recording", "start", {tracking: false});

      setBusy(true);
      siteBionicsApplcation.service.putHubRecordingRequestAsync(siteNavigator.currentHub!, new HubRecordingRequest(true, false)).then(() => {
        setBusy(false);
      })
    };

    const handleStopRecordingClick = () => {
      event("recording", "stop");

      setBusy(true);
      siteBionicsApplcation.service.putHubRecordingRequestAsync(siteNavigator.currentHub!, new HubRecordingRequest(false, false)).then(() => {
        setBusy(false);
      });
    };

    const handleRenameClick = () => {
      event("rename", "click");

      setRenameHubDialogOpen(true);
    }

    const handleRename = (name: string) => {
      event("rename", "confirm");

      setBusy(true);
      siteBionicsApplcation.service.patchHubAsync(siteNavigator.currentHub!, name).then((status: number) => {
        if (status === 200) {
          siteNavigator.currentHub!.setName(name);
        } else {
          siteBionicsApplcation.setLastError("An error occured", "error");
        }
        setRenameHubDialogOpen(false);
        setBusy(false);
      });
    }

    const handleRenameCancel = () => {
      event("rename", "cancel");

      setRenameHubDialogOpen(false);
    };

    const event = (operation: string, action?: string, additionalParams?: Record<string, any> ) => {
      const suffix = action ? "_" + action : "";
      ReactGA.event("hub_" + operation + suffix, {
        accountId: siteNavigator.site.account.accountId,
        siteId: siteNavigator.site.siteId,
        hubId: siteNavigator.currentHub!.hubId,
        ...additionalParams
      });
    };

    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    const menuOpen1 = Boolean(anchorEl1);
    const handleMenu1Click = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };

    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    const menuOpen2 = Boolean(anchorEl2);
    const handleMenu2Click = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl2(event.currentTarget);
    };
    

    return (
      <div style={{minHeight: "200px"}}>

        <TitleToolbar title="Hubs" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
          <>
            <Button variant="outlined" size="small" disabled={!siteNavigator.currentHub} onClick={handleMenu1Click}>Recording...</Button>
            <Menu anchorEl={anchorEl1} open={menuOpen1} onClose={() => {setAnchorEl1(null);}}>
                <MenuItem onClick={() => { handleStartRecordingClick(); setAnchorEl1(null);}}>Start Recording</MenuItem>
                <MenuItem onClick={() => { handleStartRecordingNoTrackingClick(); setAnchorEl1(null);}}>Start Recording (no tracking)</MenuItem>
                <MenuItem onClick={() => { handleStopRecordingClick(); setAnchorEl1(null);}}>Stop Recording</MenuItem>
            </Menu>
            <Button variant="outlined" size="small" onClick={handleMenu2Click}>Hub Management...</Button>
            <Menu anchorEl={anchorEl2} open={menuOpen2} onClose={() => {setAnchorEl2(null)}}>
              <MenuItem onClick={() => { handlePairHubClick(); setAnchorEl2(null);}}>Pair...</MenuItem>
              <MenuItem disabled={!siteNavigator.currentHub} onClick={() => { handleUnpairClick(); setAnchorEl2(null);}}>Unpair...</MenuItem>
              <MenuItem disabled={!siteNavigator.currentHub} onClick={() => { handleRenameClick(); setAnchorEl2(null);}}>Rename...</MenuItem>
              <MenuItem disabled={!siteNavigator.currentHub} onClick={() => { handleRestartClick(); setAnchorEl2(null);}}>Restart</MenuItem>
            </Menu>                        
          </>
        </TitleToolbar>
        
        <Box component="div" sx={{height: "10pt"}}/>

        {loading && <Typography>Loading...</Typography>}

        {!loading && site.hubs && site.hubs.length === 0 &&
          <Typography>No hubs available. Please Pair a hub.</Typography>
        }

        {!loading && site.hubs && site.hubs.length > 0 &&
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">Hub Name</TableCell>               
                  <TableCell align="left">Health</TableCell>
                  <TableCell align="left"># Cameras</TableCell>
                  <TableCell align="left">Video FPS</TableCell>
                  <TableCell align="left">Inference FPS</TableCell>
                  <TableCell align="left">Last Update</TableCell>
                  <TableCell align="left">Running Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {siteNavigator.site.hubs?.map((hub) => (
                  <HubTableRow key={hub.hubId} hub={hub} hubStatus={hubStatuses === null ? undefined : hubStatuses[hub.hubId]}/>              
                ))}              
              </TableBody>
            </Table>
          </TableContainer>        
        }

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

        {renameHubDialogOpen &&
          <NameDialog title="Rename Hub" name={siteNavigator.currentHub!.name} onSave={handleRename} saveLabel="Rename" nameLabel="Hub Name" onClose={handleRenameCancel} />
        }

        {unpairHubDialogOpen && 
          <Confirm
            confirmationText={"Are you sure you want to unpair " + siteNavigator.currentHub?.hubId + "?"}
            confirmButtonLabel="Unpair"
            onClose={() => setUnpairHubDialogOpen(false)}
            onConfirm={handleUnpair}/>
        }      

        {pairHubDialogOpen &&
          <PairHubDialog onClose={handlePairHubClose} siteName={siteNavigator.site.siteName} onPair={handlePair}/>
        }
      </div>
    )
})

export default Hubs;