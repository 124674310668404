import { FunctionComponent, useEffect, useState } from "react";
import { Box, CircularProgress, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { convertTimezoneOnly } from "../util/Time";
import { observer } from "mobx-react";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { DwellTimeDetail } from "../models/DwellTime";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Site from "../models/Site";

dayjs.extend(utc);
dayjs.extend(timezone);

const DwellTimeDetailChart: React.FC<{ startDate: Dayjs, site: Site }> = observer(({ startDate, site }) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [dwellTimeDetails, setDwellTimeDetails] = useState<DwellTimeDetail[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visibleRegions, setVisibleRegions] = useState<Set<string>>(new Set());
  const siteStartDate = convertTimezoneOnly(startDate, site.timeZone);

  useEffect(() => {
    setIsLoading(true);

    siteBionicsApplication.service.fetchDwellTimesDetailsAsync(site, siteStartDate.utc(), 20).then((dwellTimeDetails) => {
      setDwellTimeDetails(dwellTimeDetails);
      setVisibleRegions(new Set(dwellTimeDetails.map(item => item.regionId))); // Initialize all regions as visible
      setIsLoading(false);
    });
  }, [startDate]);

  const generateDateRange = (startDate: Dayjs, days: number): string[] => {
    return Array.from({ length: days }, (_, i) => startDate.add(i, 'day').format('MM/DD'));
  };

  const transformData = (data: DwellTimeDetail[], dateRange: string[]): { name: string, data: number[] }[] => {
    const regions = Array.from(new Set(data.map(item => item.regionId)));
    const transformedData = regions.map(region => ({
      name: region,
      data: Array(dateRange.length).fill(0), // Initialize with zeros for each day in the date range
    }));

    data.forEach(item => {
      const regionIndex = regions.indexOf(item.regionId);
      const localDate = dayjs.utc(item.date).tz(site.timeZone); // Convert to local time
      //console.log(site.timeZone);
      //console.log(`UTC Time: ${item.date}, Local Time: ${localDate.format()}`);
      const dayIndex = dateRange.indexOf(localDate.format('MM/DD'));
      if (dayIndex !== -1) {
        transformedData[regionIndex].data[dayIndex] = item.averageDwellTime;
      }
    });

    return transformedData;
  };

  const dateRange = generateDateRange(siteStartDate, 7);
  const series = dwellTimeDetails ? transformData(dwellTimeDetails, dateRange) : [];

  const formatToHHMMSS = (val: number): string => {
    const hours = Math.floor(val / 3600);
    const minutes = Math.floor((val % 3600) / 60);
    const seconds = Math.floor(val % 60); // Round seconds to the nearest integer
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      background: '#333', // Dark background
    },
    title: {
      text: 'Average Dwell Time by Section', // Your chart title
      align: 'center', // Title alignment
      style: {
        color: '#fff', // Title color
        fontSize: '20px', // Title font size
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => formatToHHMMSS(val),
      style: {
        colors: ['#fff'], // White labels
      },
    },
    xaxis: {
      categories: dateRange,
      labels: {
        style: {
          colors: '#fff', // White labels
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: '#fff', // White labels
        },
      },
    },
    legend: {
      labels: {
        colors: '#fff', // White legend labels
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: 'dark', // Dark theme for tooltip
      y: {
        formatter: (val: number) => formatToHHMMSS(val),
      },
    },
  };

  const handleCheckboxChange = (region: string) => {
    setVisibleRegions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(region)) {
        newSet.delete(region);
      } else {
        newSet.add(region);
      }
      return newSet;
    });
  };

  const handleSelectAll = () => {
    setVisibleRegions(new Set(series.map(serie => serie.name)));
  };

  const handleDeselectAll = () => {
    setVisibleRegions(new Set());
  };

  const filteredSeries = series.filter(serie => visibleRegions.has(serie.name));

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      {isLoading ? (
        <Box component="div" display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <Box component="div" display="flex" width="100%" overflow="auto">
          <Box component="div" sx={{ width: '100%' }}>
            <Box component="div" sx={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
              <Chart options={options} series={filteredSeries} type="bar" height={350} />
              <Box component="div" sx={{ display: 'flex', gap: '6px', marginBottom: '10px' }}>
                <Button variant="contained" onClick={handleSelectAll}>Select All</Button>
                <Button variant="contained" onClick={handleDeselectAll}>Deselect All</Button>
              </Box>
              {series.map(serie => (
                <FormControlLabel
                  key={serie.name}
                  control={
                    <Checkbox
                      checked={visibleRegions.has(serie.name)}
                      onChange={() => handleCheckboxChange(serie.name)}
                      sx={{ color: '#fff' }}
                    />
                  }
                  label={<Typography sx={{ color: '#fff' }}>{serie.name}</Typography>}
                />
              ))}
            </Box>

          </Box>
        </Box>
      )}
    </Box>
  );
});

export default DwellTimeDetailChart;
