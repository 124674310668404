import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Button, List, ListItem, ListItemText, Typography, useTheme, Divider } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useQueryState from "../hooks/useQueryState";
import ShiftContainer from "../charts/ShiftContainer";
import OccupancyContainer from "../charts/OccupancyContainer";
import DwellTimeSummaryContainer from "../charts/DwellTimeSummaryContainer";
import DwellTimeDetailContainer from "../charts/DwellTimeDetailContainer";

const Dashboard: FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Dashboard");
  const theme = useTheme();
  const site = useSite();

  const [selectedItem, setSelectedItem] = useQueryState<string>("dashboard" + site.siteId, siteNavigator.pageState, "si", "", (value) => value, value => value); 

  const handleItemClick = (item: string) => {
    setSelectedItem(item, true, true);
  };

  return (
    <Box component="div" display="flex" flexDirection="column" height="100%" overflow="hidden">
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <Divider />

      {/* Main Content Area */}
      <Box component="div" display="flex" overflow="hidden" flexGrow="1">

        {/* Navigation Pane */}
        <Box component="div" minWidth="250px" width="250px" height="100%" bgcolor={theme.palette.background.paper} p={2}>
          <Typography variant="h6">Store Reports</Typography>
          <List>           
            <ListItem button onClick={() => handleItemClick("Occupancy")}>
              <ListItemText primary="Occupancy" />
            </ListItem>
            <ListItem button onClick={() => handleItemClick("Dwell Time Summary")}>
              <ListItemText primary="Dwell Time Summary" />
            </ListItem>
            <ListItem button onClick={() => handleItemClick("Dwell Time Detail")}>
              <ListItemText primary="Dwell Time Detail" />
            </ListItem>
          </List>
          <Typography variant="h6" mt={2}>Team Planning</Typography>
          <List>
            <ListItem button onClick={() => handleItemClick("Staffing")}>
              <ListItemText primary="Staffing" />
            </ListItem>
          </List>
          <Box component="div" sx={{ flexGrow: 1 }} />
        </Box>

        <Divider orientation="vertical" flexItem />

        {/* Chart */ }
        {selectedItem === "Staffing" &&
          <ShiftContainer pageState={siteNavigator.pageState} site={site} />
        }
        {selectedItem === "Occupancy" &&
          <OccupancyContainer pageState={siteNavigator.pageState} site={site} />
        }
        {selectedItem === "Dwell Time Summary" &&
          <DwellTimeSummaryContainer pageState={siteNavigator.pageState} site={site} />
        }
        {selectedItem === "Dwell Time Detail" &&
          <DwellTimeDetailContainer pageState={siteNavigator.pageState} site={site} />
        }
        
      </Box>
    </Box>
  );
});

export default Dashboard;
