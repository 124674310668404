import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem, TextField, Grid, FormControl, InputLabel } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import duration, {Duration} from 'dayjs/plugin/duration';
import SiteNavigator from '../models/SiteNavigator';
import { useSiteNavigator } from '../pages/SitePage';
import { observer } from 'mobx-react';


interface SnapshotViewerDialogProps {
    open: boolean;
    onClose: () => void;
    startTime: Dayjs;
    duration: Duration;
}

const SnapshotViewerDialog: React.FC<SnapshotViewerDialogProps> = observer(({ open, onClose, startTime, duration }) => {
    const siteNavigator = useSiteNavigator();
    const imageRef = useRef<HTMLImageElement>(null);
    const [snapshotUrl, setSnapShotUrl] = useState<string | undefined>(undefined);

    // this effect is called whenever the current camera changes
    // and sets the current camera entity and the snapshot url
    useEffect(() => {
        // siteNavigator.currentCamera?.getSnapShotSasUrlAsync().then((url: string) => {
        //     setSnapShotUrl(url);
        // });
        siteNavigator.currentCamera?.getSnapShots(startTime, startTime.add(duration.asMilliseconds(), 'millisecond')).then((snapshots: any) => {
            if (snapshots.length > 0) {
                setSnapShotUrl(snapshots[0].sasUri);
            }
        });
    }, [siteNavigator.currentCamera,  startTime, duration]);

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        siteNavigator.site.loadCamerasAsync().then(() => {
            if (siteNavigator.site.cameras && siteNavigator.site.cameras.length > 0) {
                siteNavigator.setCurrentCamera(siteNavigator.site.cameras[0])
            }
        });
      }, []);
    
    return (
        <Dialog open={open} onClose={onClose}  maxWidth={false} 
            PaperProps={{
            style: {width: '75vw', maxWidth: 'none', // Ensures no default maxWidth constraints
            },
            }}>
            <DialogTitle>Snapshots</DialogTitle>
            <DialogContent>
                <FormControl sx={{marginY: "20px"}}>
                    <InputLabel id="camera-list-label">Camera</InputLabel>
                    <Select
                        labelId="camera-list-label"
                        id="camera-list"
                        sx={{width: "100pt"}}
                        value={siteNavigator.currentCamera?.cameraId}
                        label="Camera"            
                        onChange={(event) => {
                            siteNavigator.setCurrentCameraById(event.target.value);
                        }}>
                        {siteNavigator.site.cameras?.map((c) => (
                            <MenuItem key={c.cameraId} value={c.cameraId}>{c.cameraName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <img ref={imageRef} src={snapshotUrl} alt=""
                 style={{backgroundColor: "black", width: '100%',  objectFit: 'contain', objectPosition: 'center'}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
});

export default SnapshotViewerDialog;
