import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import 'm-react-splitters/lib/splitters.css';
import TrackedObject from "../models/TrackedObject";
import { encodeColor } from "../util/Colors";
import Tracklet, { TrackletType } from "../models/Tracklet";
import { Color3 } from "@babylonjs/core";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { createPortal } from "react-dom";
import SiteNavigator from "../models/SiteNavigator";
import { forEachChild } from "typescript";
import Camera from "../models/Camera";
import Track from "video.js/dist/types/tracks/track";
import TrackedObjectsTimeline from "./TrackedObjectsTimeline";
import TrackletsTimeline from "./TrackletsTimeline";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <>
        {value === index && 
            <Box id="test" component="div" overflow="auto" height="100%" width="100%">
                {children}
            </Box>
        }
        </>
    );
}

function a11yProps(index: number) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}
  

const TrackedObjectOverview: React.FC<{ trackedObject?: TrackedObject }> = ({ trackedObject }) => {
    if (!trackedObject) return null;
  
    // Prepare data for the pie chart
    const chartLabels = Object.keys(trackedObject.timeEngagedWithTags);
    const chartData = Object.values(trackedObject.timeEngagedWithTags);
  
    const totalTime = (trackedObject.endTime.getTime() - trackedObject.startTime.getTime()) / 1000;
    //const engagedTimeSum = Object.values(trackedObject.timeEngagedWithTags).reduce((acc, value) => acc + value, 0);

    // Calculate the time not engaged with any tag
    //const otherTime = totalTime - engagedTimeSum;
  
    chartLabels.push("Total Time");
    chartData.push(totalTime);  
    const options: ApexOptions = {
        chart: { 
            type: 'bar',
            animations: { enabled: false }
        },
        plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
        theme: { mode: 'dark' },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                // Ensure val is treated as a number
                const totalSeconds = Array.isArray(val) ? val[0] : Number(val);
        
                // Convert seconds to hh:mm:ss.0 format
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = Math.floor(totalSeconds % 60);
                const tenths = Math.floor((totalSeconds % 1) * 10); // get tenths of a second
        
                const pad = (num: number) => String(num).padStart(2, '0');
                return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${tenths}`;
              },
            style: {
              colors: ['#fff']
            },
            offsetX: 20,
          },
        xaxis: { categories: chartLabels},
    };

    const chartSeries = [{
        name: 'Time (ms)',
        data: chartData,
      }];
    
      return (
        <Grid container spacing={2} style={{ padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 5px rgba(0,0,0,0.3)' }}>
          {/* Left Panel */}
          <Grid item xs={6}>
            <Typography variant="h5">{trackedObject.isStationary ? "Person (Stationary)" : "Person"}</Typography>
            <Typography variant="body1">Start: {trackedObject.startTime.toLocaleTimeString()} {trackedObject.startsAtEntrance ? " @ " + trackedObject.startsAtEntrance : ""}</Typography>
            <Typography variant="body1">End: {trackedObject.endTime.toLocaleTimeString()} {trackedObject.endsAtEntrance ? " @ " + trackedObject.endsAtEntrance : ""}</Typography>
            <Typography variant="body2">({trackedObject?.trackedObjectId})</Typography>
          </Grid>
          {/* Right Panel */}
          <Grid item xs={6}>
            <ReactApexChart height={chartSeries[0].data.length * 30 + 50} options={options} series={chartSeries} type="bar" width="100%"  />
          </Grid>
        </Grid>
      );
    };
    
  

interface TrackedObjectDetailsProps {
    selectTrackedObject: (trackedObject: TrackedObject) => void;
    selectTracklet: (tracklet: Tracklet) => void;
    trackedObjects: TrackedObject[];
    tracklets: Tracklet[];
    siteNavigator: SiteNavigator;
    showTracklets: boolean;
    showStationaryTrackedObjects: boolean;
    showShortLivedTrackedObjects: boolean;
  }

const TrackedObjectDetails: FC<TrackedObjectDetailsProps> = observer(({
   selectTrackedObject, selectTracklet, trackedObjects, tracklets, siteNavigator, showTracklets, showStationaryTrackedObjects: showStationaryTrackledObjects, showShortLivedTrackedObjects: showShortLivedTrackedObjects
  }) => {
    const siteBionicsApplication = useSiteBionicsApplication();
    const [selectedTab, setSelectedTab] = useState(0);
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
      };
    
      
        
    
    return (
        <Box component="div" id="TrackedObjectDetail" display="flex" flexGrow="1" flexDirection={"column"} overflow="hidden" width="100%" height="100%">
            <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Timeline" {...a11yProps(0)} />
                    <Tab label="Tracking Summary" {...a11yProps(1)} />
                    <Tab label="Video Segments" {...a11yProps(2)} />
                    <Tab label="Transactions" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={selectedTab} index={0}>
                <TrackedObjectsTimeline trackedObjects={trackedObjects} siteNavigator={siteNavigator} selectTrackedObject={selectTrackedObject} showStationaryTrackedObjects={showStationaryTrackledObjects} showShortLivedTrackedObjects={showShortLivedTrackedObjects}/>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
                <TrackedObjectOverview trackedObject={siteNavigator.currentTrackedObject}/>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={2}>
                <TrackletsTimeline siteNavigator={siteNavigator} selectTracklet={selectTracklet}/>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={3}>
                <Stack  >
                    <Typography>Data Start Time: {siteNavigator.startTime?.format('HH:mm:ss')}</Typography>
                    <Typography>Video Start Time: {siteNavigator.videoStartTime?.format('HH:mm:ss')}</Typography>
                    <Typography>Video Time:       {siteNavigator.videoTime?.format('HH:mm:ss')}</Typography>
                    <Typography>Current Time:     {siteNavigator.currentTime?.format('HH:mm:ss')}</Typography>
                    <Typography>Video End Time:   {siteNavigator.videoEndTime?.format('HH:mm:ss')}</Typography>
                    <Typography>Data End Time:    {siteNavigator.endTime?.format('HH:mm:ss')}</Typography>
                </Stack>
            </CustomTabPanel>
    </Box>
    )
})

export default TrackedObjectDetails;