import { Vector3 } from "@babylonjs/core";
import TrackedObject from "./TrackedObject";
import TrackletDataPoint from "./TrackletDataPoint";
import Camera from "./Camera";
import { decodeBase64ToFloatArray, decodeBase64ToShortArray, decodeBase64ToUShortArray } from "../util/JsonUtil";

export enum TrackletType { Fused, CameraDetection }

export default class Tracklet {

    trackletId: string;
    objectId: string;
    trackedObject?: TrackedObject;
    camera?: Camera;
    startTime: Date;
    endTime: Date;
    isStationary: boolean = false;
    startsAtEntrance?: string;
    endsAtEntrance?: string;
    dataPoints: TrackletDataPoint[] = [];
    trackletType: TrackletType = TrackletType.CameraDetection;

    constructor(trackletId: string, trackletType: TrackletType, objectId: string, startTime: Date, endTime: Date, isStationary: boolean, startsAtEntrance: string, endsAtEntrance: string, camera?: Camera) {
        this.trackletId = trackletId;
        this.trackletType = trackletType;
        this.objectId = objectId;
        this.camera = camera;
        this.startTime = startTime;
        this.endTime = endTime;
        this.isStationary = isStationary;
        this.startsAtEntrance = startsAtEntrance;
        this.endsAtEntrance = endsAtEntrance;
    }

    setTrackedObject(trackedObject: TrackedObject) {
        this.trackedObject = trackedObject;
    }

    setCamera(camera: Camera) {
        this.camera = camera
    }

    overlapsTrackedObject (trackedObject: TrackedObject): boolean {
        return !(trackedObject.startTime > this.endTime || trackedObject.endTime < this.startTime);
      }

    overlapsTracklet (tracklet: Tracklet): boolean {
    return !(tracklet.startTime > this.endTime || tracklet.endTime < this.startTime);
    }

    findClosestDataPoint (currentTime: Date): TrackletDataPoint | undefined {
        if (currentTime < this.startTime || currentTime > this.endTime) 
            return undefined;

        let closestDataPoint : TrackletDataPoint | undefined = undefined;
        let minTimeDiff = 2500; //2.5 seconds
      
        this.dataPoints.forEach(dp => {
          const timeDiff = Math.abs(currentTime.getTime() - dp.time.getTime());
          if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            closestDataPoint = dp;
          }
        });
      
        return closestDataPoint;
      };

    ToPoints(offset : Vector3) : Vector3[] {
        return this.dataPoints.map(dp => { return new Vector3(- dp.position.x + offset.x, dp.position.y + offset.y, dp.position.z + offset.z) });
    }

    static AddTrackletDataPointsFromBuckets(buckets: any[], trackletMap: Record<string, Tracklet>) {
      buckets.forEach((bucket, index) => {
          const trackletId = bucket.trackletId;
          const tracklet = trackletMap[trackletId];
              
          const startTime = new Date(bucket.startTime + 'Z').toUtc();
          const bboxTop = decodeBase64ToShortArray(bucket.bBoxTop).map(n => n / 32767);
          const bboxLeft = decodeBase64ToShortArray(bucket.bBoxLeft).map(n => n / 32767);
          const bboxWidth = decodeBase64ToShortArray(bucket.bBoxWidth).map(n => n / 32767);
          const bboxHeight = decodeBase64ToShortArray(bucket.bBoxHeight).map(n => n / 32767);
          const posXArray = decodeBase64ToFloatArray(bucket.posX);
          const posYArray = decodeBase64ToFloatArray(bucket.posY);
          const posZArray = decodeBase64ToFloatArray(bucket.posZ);
          const confidenceArray = decodeBase64ToFloatArray(bucket.confidence);
          const timeArray = decodeBase64ToUShortArray(bucket.timeOffset).map(timeOffset => new Date(startTime.getTime() + 10 * timeOffset));
          
          if (tracklet) {
            // if (tracklet.trackletId === "0e503367-9a7f-43d2-bdc8-3889e52164c3") {
            //   console.log("******** found it");
            // }
  
            if (tracklet.trackletType === TrackletType.CameraDetection) {
              for (let i = 0; i < timeArray.length; i++) {
                tracklet.dataPoints.push(
                  new TrackletDataPoint(trackletId, timeArray[i],
                  new Vector3(posXArray[i], posYArray[i], posZArray[i]),
                  [ bboxLeft[i], bboxTop[i], bboxWidth[i], bboxHeight[i] ],
                  confidenceArray[i]));
              }
            }
          }
      });
  }
}