import React, { FunctionComponent, useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { convertTimezoneOnly } from "../util/Time";
import { observer } from "mobx-react";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { DwellTimeSummary } from "../models/DwellTime";
import Site from "../models/Site";
import ColoredBox from "../components/ColoredBox"; // Import the ColoredBox component

const bucketSize = 20;
const minFilter = 21;
const maxFilter = 300;

const DwellTimeSummaryChart: React.FC<{ startDate: Dayjs, endDate: Dayjs, site: Site }> = observer(({ startDate, endDate, site }) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [dwellTimeSummaries, setDwellTimeSummaries] = useState<DwellTimeSummary[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const siteStartDate = convertTimezoneOnly(startDate, site.timeZone);
  const siteEndDate = convertTimezoneOnly(endDate, site.timeZone);

  useEffect(() => {
    setIsLoading(true);

    siteBionicsApplication.service.fetchDwellTimeSummariesAsync(site, siteStartDate.utc(), siteEndDate.utc(), 20).then((dwellTimeSummaries) => {
      setDwellTimeSummaries(dwellTimeSummaries);
      setIsLoading(false);
    });
  }, [startDate, endDate]);

  function convertSecondsToHMS(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    if (hours > 0) {
      return `${hours}h${minutes < 10 ? '0' : ''}${minutes}m${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    } else {
      return `${minutes < 10 ? '0' : ''}${minutes}m${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    }
  }

  const totalAverageDwellTime = dwellTimeSummaries?.reduce((total, summary) => total + summary.averageDwellTime, 0) || 0;

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      {isLoading ? (
        <Box component="div" display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
          <Box component="div" display="flex" width="100%" overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Section</TableCell>
                  <TableCell>Average time in section</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dwellTimeSummaries?.map((summary, index) => (
                  <React.Fragment key={summary.regionId}>
                    <TableRow>
                      <TableCell>{summary.regionId}</TableCell>
                      <TableCell>{convertSecondsToHMS(summary.averageDwellTime)}</TableCell>
                    </TableRow>
                    {index < dwellTimeSummaries.length - 1 && (
                      <TableRow>
                        <TableCell colSpan={2} sx={{ padding: 0 }}>
                          <Box component="div" sx={{ height: '2pt', backgroundColor: 'aqua', width: `${(summary.averageDwellTime / totalAverageDwellTime) * 100}%` }} />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>
      )}
    </Box>
  );
});

export default DwellTimeSummaryChart;
