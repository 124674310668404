import React, { ReactNode, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs, ManipulateType } from "dayjs";

function findClosestValue(inputValue: number, values: number[]) {
  // Sort the array to ensure it is in ascending order
  const sortedValues = values.slice().sort((a, b) => a - b);

  // If the input value is below the first entry, return the first entry
  if (inputValue < sortedValues[0]) {
    return sortedValues[0];
  }

  // Find the closest value not greater than the input value
  let closestValue = sortedValues[0];
  for (let value of sortedValues) {
    if (value <= inputValue) {
      closestValue = value;
    } else {
      break; // Stop iteration once we find a value larger than the input
    }
  }

  return closestValue;
}

// Define the shape of the props for the component
interface TimeSpanShortControlProps {

  onChange?: null | ((from: Dayjs, duration: number, to: Dayjs) => void);

  startLabel?: string;
  endLabel?: string;
  durationLabel?: string;

  startOnly?: boolean;   
  
  initialStartDate: Dayjs;
  initialDuration: number;

  granularity?: 'small' | 'medium';

  sx?: object; // Add sx prop
}

const TimeSpanShortControl: React.FC<TimeSpanShortControlProps> = ({
  onChange = null,
  startLabel = "Start",
  endLabel = "End",
  durationLabel = "Duration",

  startOnly = false, 
  
  initialStartDate,
  initialDuration,

  granularity = 'small',
  
  sx 
}) => {

  // Define the options for the duration dropdown
  const durationOptionsSmall = ['1 Minute', '2 Minutes', '3 Minutes', '4 Minutes', '5 Minutes'];
  const durationValuesSmall = [60, 120, 180, 240, 300];  

  const durationOptionsMedium = ['1 Minute', '2 Minutes', '3 Minutes', '4 Minutes', '5 Minutes', '10 Minutes', '15 Minutes', '30 Minutes', '1 Hour'];
  const durationValuesMedium = [60, 120, 180, 240, 300, 600, 900, 1800, 3600];

  const durationOptions = granularity === 'small' ? durationOptionsSmall : durationOptionsMedium;
  const durationValues = granularity === 'small' ? durationValuesSmall : durationValuesMedium;

  const adjustedInitialDuration = durationValues.includes(initialDuration) ? initialDuration : findClosestValue(initialDuration, durationValues);

  // State hooks to manage user input
  const [startDate, setStartDate] = useState<Dayjs>(initialStartDate.startOf('minute'));
  const [duration, setDuration] = useState<number>(adjustedInitialDuration);

  const [endDate, setEndDate] = useState<Dayjs>(initialStartDate.add(adjustedInitialDuration, 'second'));

  const changeStartDate = (date: Dayjs | null) => {
    if (date === null) return;
    setStartDate(date);
    let newEndDate = date.add(Math.min(duration, durationValues.at(-1)!), 'second');
    setEndDate(newEndDate);
    if (onChange) {
      onChange(date, duration, newEndDate);
    }
  }

  const changeEndDate = (date: Dayjs | null) => {
    if (date === null) return;
    setEndDate(date);
    const newStartDate = date.subtract(Math.min(duration, durationValues.at(-1)!), 'second');
    setStartDate(newStartDate);
    if (onChange) {
      onChange(newStartDate, duration, date);
    }
  }

  // Render DateTimePicker component
  const renderDateTimePicker = (
    label: string,
    value: Dayjs | null,
    onChange: (date: Dayjs | null) => void
  ) => {    
    return (
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        ampm={true}
      />
    );
  };

  const changeDuration = (value: number) => {

    const newEndDate = startDate.add(Math.min(value, durationValues.at(-1)!), 'second');
    setEndDate(newEndDate);
    setDuration(value);
    if (onChange) {
      onChange(startDate, value, newEndDate);
    }
  }

  // Render Duration dropdown
  const renderDurationDropdown = (label: string) => {
    return (
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          value={duration}
          onChange={(e) => changeDuration(e.target.value as number)}
          label={label}
        >
          {durationOptions.map((option, index) => (
            <MenuItem key={index} value={durationValues[index]}>
              {option}
            </MenuItem>
          ))}
          
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Box component="div" display="flex" flexDirection="row" gap={2} sx={sx}>
        {renderDateTimePicker(startLabel, startDate, changeStartDate)}
        {renderDurationDropdown(durationLabel)}
        {(!startOnly) && renderDateTimePicker(endLabel, endDate, changeEndDate)}
      </Box>
    </>
  );
};

export default TimeSpanShortControl;
