import { Color3, Vector3 } from '@babylonjs/core';
import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';


@model("SiteBionics/Color3Model")
class Color3Model extends Model({
    r: prop<number>(0).withSetter(),
    g: prop<number>(0).withSetter(),
    b: prop<number>(0).withSetter()
  },
  {
    valueType: true
  }
) {

  
  @computed
  get asColor3() : Color3 {
    return new Color3(this.r, this.g, this.b);
  }

  @computed
  get asHex() : string {
    return new Color3(this.r, this.g, this.b).toHexString();
  }

  static fromColor3(value: Color3): Color3Model {
    return new Color3Model({ r: value.r, g: value.g, b: value.b });
  }
}

export default Color3Model;