import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import 'm-react-splitters/lib/splitters.css';
import TrackedObject from "../models/TrackedObject";
import { encodeColor } from "../util/Colors";
import Tracklet, { TrackletType } from "../models/Tracklet";
import { Color3 } from "@babylonjs/core";
import { Box, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { createPortal } from "react-dom";
import SiteNavigator from "../models/SiteNavigator";
import { forEachChild } from "typescript";
import Camera from "../models/Camera";
import Track from "video.js/dist/types/tracks/track";





interface TrackedObjectsTimelineProps {
    selectTrackedObject?: (trackedObject: TrackedObject) => void;
    trackedObjects: TrackedObject[];
    siteNavigator: SiteNavigator;
    showStationaryTrackedObjects: boolean;
    showShortLivedTrackedObjects: boolean;
}

const TrackedObjectsTimeline: FC<TrackedObjectsTimelineProps> = observer(({
    selectTrackedObject: onTrackedObjectClicked, trackedObjects, siteNavigator, showStationaryTrackedObjects: showStationaryTrackledObjects, showShortLivedTrackedObjects: showShortLivedTrackledObjects }) => {

    const siteBionicsApplcation = useSiteBionicsApplication();
    const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});

    const minTime = siteNavigator.startTime?.toDate().getTime() ?? 0;
    const maxTime = siteNavigator.endTime?.toDate().getTime() ?? 0;
    const timeSpan = maxTime - minTime;

    // Scroll to the selected row when the current TrackedObject changes
    useEffect(() => {
        if (siteNavigator.currentTrackedObject  && rowRefs.current[siteNavigator.currentTrackedObject.trackedObjectId]) {
        rowRefs.current[siteNavigator.currentTrackedObject.trackedObjectId]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        }
    }, [siteNavigator.currentTrackedObject]);
    
    const handleClick = (event: React.MouseEvent<unknown>, trackedObjectId: string) => {
        if (onTrackedObjectClicked) {
            const trackedObject = trackedObjects.find(to => to.trackedObjectId === trackedObjectId);
            if (trackedObject) {
                onTrackedObjectClicked(trackedObject);
            }
        }        
    };
    
    const handleDoubleClick = (event: React.MouseEvent<unknown>, trackedObjectId: string) => {
        if (onTrackedObjectClicked) {
            const trackedObject = trackedObjects.find(to => to.trackedObjectId === trackedObjectId);
            if (trackedObject) {
                onTrackedObjectClicked(trackedObject);
            }
        }        
    };

    const getBarStyle = (trackedObject: TrackedObject) => {
        let startTime = trackedObject.startTime.getTime();
        let endTime = trackedObject.endTime.getTime();
        startTime = Math.max(startTime, minTime);
        endTime = Math.min(endTime, maxTime);
        const leftOffset = ((startTime - minTime) / timeSpan) * 100;
        const width = ((endTime - startTime) / timeSpan) * 100;
        let color = trackedObject.color;
        return {
            marginLeft: `${leftOffset}%`,
            width: `${width}%`,
            height: '100%',
            backgroundColor: encodeColor(color),
            borderRadius: '3px'
        };
    };

    return (
        <Box component="div">
            {trackedObjects && trackedObjects.length > 0 &&
                <TableContainer sx={{ width: "100%", height: "100%", paddingLeft: "10pt", paddingRight: "10pt" }}>
                    <Table size="small" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}>#</TableCell>
                                <TableCell>Time Span</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowX: "auto" }}>
                            {trackedObjects.filter(to=> 
                                    (showStationaryTrackledObjects || !to.isStationary) &&
                                    (showShortLivedTrackledObjects || (to.endTime.getTime() - to.startTime.getTime()) > 10000) // 10 seconds
                                    ).map((to, index) => (
                                <TableRow
                                    key={to.trackedObjectId} 
                                    onClick={(event) => handleClick(event, to.trackedObjectId)}
                                    onDoubleClick={(event) => handleDoubleClick(event, to.trackedObjectId)}
                                    selected={to.trackedObjectId === siteNavigator.currentTrackedObject?.trackedObjectId}
                                    ref={(el) => (rowRefs.current[to.trackedObjectId] = el)}>
                                    <TableCell>{index}</TableCell>
                                    <TableCell>
                                        <div style={{ position: 'relative', width: '100%', height: '20px' }}>
                                            <div style={getBarStyle(to)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {!trackedObjects &&
                <p>No tracked objects. Select a time range and hit 'refresh'.</p>
            }
        </Box>
    )
})

export default TrackedObjectsTimeline;