import { FunctionComponent, useEffect, useState } from "react";
import { Select, IconButton, Button, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import { convertTimezoneOnly, hourToLabel, minutesToLabel } from "../util/Time";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import Shift from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { toZonedTime, format } from 'date-fns-tz';
import Site from "../models/Site";
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';

interface HeatmapData {
  time: number; // Time in minutes (0-1439)
  days: Array<{ date: Dayjs, employees: Array<string> }>; // Array of each day, list of employees in that time slot in each day
}

export function exportHeatmapDataToCSV2(heatmapData: HeatmapData[], site: Site, fileName: string = 'staffing_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];
  csvRows.push(headerRow.join(','));
  
  // Loop through each day (0 to 6)
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    // Loop through each time slot
    heatmapData.forEach(timeData => {
      if (timeData.time >= site.openHours[dayIndex * 2] && timeData.time <= site.openHours[dayIndex * 2 + 1]) {
        const dayData = timeData.days[dayIndex];
        const row = [`${dayData.date.format('MM/DD/YYYY')} ${minutesToLabel(timeData.time, true)}`, dayData.employees.length.toString()];
        csvRows.push(row.join(','));
      }
    });
  }

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}


export function exportHeatmapDataToCSV(heatmapData: HeatmapData[], showAllHours: boolean, fileName: string = 'staffing_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Hour'];
  if (heatmapData.length > 0) {
    heatmapData[0].days.forEach((dayData) => {
      headerRow.push(dayData.date.format('MM/DD/YYYY'));
    });
  }
  csvRows.push(headerRow.join(','));

  // Determine the min and max staffed times
  let minStaffedTime = Number.MAX_SAFE_INTEGER;
  let maxStaffedTime = Number.MIN_SAFE_INTEGER;

  for (const timeData of heatmapData) {
    const time = timeData.time;
    for (const day of timeData.days) {
      if (day.employees.length > 0) {
        minStaffedTime = Math.min(minStaffedTime, time);
        maxStaffedTime = Math.max(maxStaffedTime, time);
      }
    }
  }

  // Add the data rows
  heatmapData.forEach(timeData => {
    if (showAllHours || (timeData.time >= minStaffedTime && timeData.time <= maxStaffedTime)) {
      const row = [minutesToLabel(timeData.time, true)];
      timeData.days.forEach(dayData => {
        row.push(dayData.employees.length.toString());
      });
      csvRows.push(row.join(','));
    }
  });

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}

const ShiftChart: React.FC<{ date: Dayjs, site: Site }> = observer(({ date, site }) => {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const siteBionicsApplcation = useSiteBionicsApplication();
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAllHours, setShowAllHours] = useState<boolean>(false);
  const siteDate = convertTimezoneOnly(date.startOf('day'), site.timeZone);
  const [granularity, setGranularity] = useState<number>(60);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    theme: { mode: 'dark' },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.0000001,
              color: '#999999'
            },
            {
              from: 0.0000001,
              to: Number.MAX_VALUE,
              color: '#55FF55'
            }
          ],
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (typeof val === 'number') {
          return (val === 0) ? "" : val.toString();
        } else if (val === null) {
          return "";
        } else {
          return val.toString();
        }
      },
      style: {
        colors: ['#fff']
      }
    },
    legend: { show: false },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: []  // This will be dynamically set
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const yValue = series[seriesIndex][dataPointIndex];

        // Disable tooltip for null y values
        if (yValue === null || yValue === 0) {
          return ''; // Return an empty string to not display the tooltip
        }

        // Default tooltip content if yValue is not null
        return `<div style="padding: 5px;">
              <strong>Total:</strong> ${yValue}<br>
              <strong>Employees:</strong><br>
              ${w.config.series[seriesIndex].data[dataPointIndex].employees}
            </div>`;
      }
    },
  });

  function transformShiftsToHeatmap(
    shifts: Shift[],
    utcStart: Date,
    utcEnd: Date,
    interval: number
  ): HeatmapData[] {

    const timeSlotDataMap: Map<number, Map<string, string[]>> = new Map(); // { timeSlot : { day : [employees]}}

    // Helper function to get day part of a date
    const getDay = (date: Date) => dayjs(date).format('ddd M/D');

    const start = toZonedTime(utcStart, site.timeZone);
    const end = toZonedTime(utcEnd, site.timeZone);

    // Populate the timeSlotDataMap with employees working in each time slot in each day

    // Go through the shifts
    for (let i = 0; i < shifts.length; i++) {
      let shift = shifts[i];
      const utcShiftStart = new Date(shift.startTime);
      const utcShiftEnd = new Date(shift.endTime);

      if (utcShiftStart >= utcShiftEnd) break;

      const shiftStart = toZonedTime(utcShiftStart, site.timeZone);
      const shiftEnd = toZonedTime(utcShiftEnd, site.timeZone);
      shiftEnd.setSeconds(shiftEnd.getSeconds() - 1);

      const timeZone = site.timeZone;

      // Start at the shift start; going till the end or the end of the range
      while (shiftStart < shiftEnd && shiftStart < end) {

        // Current time slot being processed
        let timeSlot = Math.floor((shiftStart.getHours() * 60 + shiftStart.getMinutes()) / interval) * interval;

        // Current day
        let currentDay1 = getDay(shiftStart);
        let currentDay = shiftStart.toDateString();


        // Ensure the map has these entries
        if (!timeSlotDataMap.has(timeSlot)) {
          timeSlotDataMap.set(timeSlot, new Map());
        }

        const dayMap = timeSlotDataMap.get(timeSlot)!;
        if (!dayMap.has(currentDay)) {
          dayMap.set(currentDay, []);
        }

        // Add to the map
        const entry = shift.employeeId + " " + shift.firstName + " " + shift.lastName;
        if (!dayMap.get(currentDay)!.includes(entry)) {
          dayMap.get(currentDay)!.push(entry);
        }

        // Move to the next time slot
        shiftStart.setMinutes(shiftStart.getMinutes() + interval);
      }
    }

    // Now convert the timeSlotDataMap to the heatmapData needed to render

    const heatmapData: HeatmapData[] = [];

    // Loop through each time slot of the day
    for (let timeSlot = 0; timeSlot < 1440; timeSlot += interval) {

      const dayData: Array<{ date: Dayjs, day: string, employees: Array<string> }> = [];
      const dayMap = timeSlotDataMap.get(timeSlot) || new Map();

      // Loop through each day in the range
      for (let date = new Date(start); date < end; date.setDate(date.getDate() + 1)) {

        const day1 = getDay(date);
        const day = date.toDateString();
        const employees = dayMap.get(day) || [];
        const f = dayjs(date);

        dayData.push({ date: f, day, employees });
      }

      heatmapData.push({
        time: timeSlot,
        days: dayData
      });
    }

    return heatmapData;
  }

  useEffect(() => {
    setIsLoading(true);

    siteBionicsApplcation.service.fetchShiftsAsync(site, siteDate.utc(), siteDate.add(1, 'week').utc()).then((shifts) => {
      var hmd = transformShiftsToHeatmap(shifts, siteDate.utc().toDate(), siteDate.add(1, 'week').utc().toDate(), granularity);
      setHeatmapData(hmd);
      setIsLoading(false);
    });
  }, [date, granularity]);

  useEffect(() => {
    const data = heatmapData;

    let minStaffedTime = Number.MAX_SAFE_INTEGER;
    let maxStaffedTime = Number.MIN_SAFE_INTEGER;

    for (const timeData of data) {
      const time = timeData.time;
      for (const day of timeData.days) {
        if (day.employees.length > 0) {
          minStaffedTime = Math.min(minStaffedTime, time);
          maxStaffedTime = Math.max(maxStaffedTime, time);
        }
      }
    }

    // Only show the time slots the user wants to see
    const seriesData = data.filter((timeData) => {
      return (showAllHours || (timeData.time >= minStaffedTime && timeData.time <= maxStaffedTime));
    })
      // Render the visible time slots
      .map((timeData) => {

        const timeInMinutes = timeData.time;

        return {
          name: minutesToLabel(timeData.time, false),

          // Each day
          data: timeData.days.map((dayData) => {

            if (showAllHours || (timeInMinutes >= minStaffedTime && timeInMinutes <= maxStaffedTime)) {
              return {
                x: dayData.date.format('MM/DD/YYYY'),
                y: dayData.employees.length,

                // Each employee (for the hover)
                employees: dayData.employees.join("<br/>")
              }
            } else {
              return {
                x: dayData.date.format('MM/DD/YYYY'),
                y: null
              }
            }
          })
        } 
      }).reverse(); // Reverse the order of the time slots, but not the days

    setSeriesData(seriesData);
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data[0]?.days.map((day: any) => day.date.format('ddd M/D')) || []  // Keep the original x-axis categories
      }
    }));
  }, [date, heatmapData, showAllHours, granularity]);

  const chartHeight = seriesData.length * 30 + 100;

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (seriesData.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }   

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      <Box component="div" style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
        <FormControlLabel
          control={
            <Checkbox checked={showAllHours} onChange={() => setShowAllHours(prev => !prev)} />
          }
          label="Show Unstaffed Hours"
        />
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="granularity-label">Granularity</InputLabel>
          <Select
            labelId="granularity-label"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value as number)}
            label="Granularity"
          >
            <MenuItem value={60}>By Hour</MenuItem>
            <MenuItem value={30}>By 30 Minutes</MenuItem>
            <MenuItem value={15}>By 15 Minutes</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          color="primary"
          onClick={() => exportHeatmapDataToCSV2(heatmapData, site)}
          sx={{ marginLeft: 2 }}
        >
          <DownloadIcon />
        </IconButton>
      </Box>

      <Box component="div" display="flex" width="100%" overflow="auto">
        <Box component="div" sx={{ minHeight: chartHeight, width: '100%' }}>
          <ReactApexChart options={options} series={seriesData} height={chartHeight} width="100%" type="heatmap" />
        </Box>
      </Box>
    </Box>
  );

});

export default ShiftChart;
