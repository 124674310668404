import { useState, FunctionComponent, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import UserInfo from "../models/UserInfo";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { SiteBionicsService } from "../models/SiteBionicsService";
const Profile : FunctionComponent = () => {
    

    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [msalToken, setMsalToken] = useState<string | null>(null);

    const siteBionicsApplication = useSiteBionicsApplication();

    function RequestMsalToken() {
        instance.acquireTokenSilent({
            scopes: ["api://0136c655-9040-40b0-951a-72268d5396a2/Sites.Read"], //, "User.Read", "User.ReadBasic.All"
            account: accounts[0]
        }).then((response : any) => {
            setMsalToken(response.accessToken);
        });

    }    

    function RequestAccessToken() {
        siteBionicsApplication.service.getAccessToken().then((accessToken) => {
            setAccessToken(accessToken)
        });        
    }    
    

    function RequestUser() {
        const url = `${SiteBionicsService.serverRoot}/api/users/${siteBionicsApplication.me!.id}`
        siteBionicsApplication.service.fetchWithToken(url, "sbjwt").then((response) => {
            response.json().then((responseObj) => {
                const d = JSON.stringify(responseObj);
                alert(d);
            })
        })
    }
    if (siteBionicsApplication.me === undefined) return (<></>)

    return (
        <Box component="div" margin={5} flexGrow="1" display="flex" flexDirection="column">
            <Grid container spacing={2}>
                <Grid item xs={4}>First name:</Grid>
                <Grid item xs={8} data-testid="first-name">{siteBionicsApplication.me.firstName}</Grid>
                <Grid item xs={4}>Last name:</Grid>
                <Grid item xs={8} data-testid="last-name">{siteBionicsApplication.me.lastName}</Grid>
                <Grid item xs={4}>Username:</Grid>
                <Grid item xs={8} data-testid="user-name">{siteBionicsApplication.me.email}</Grid>
                <Grid item xs={4}>User Id:</Grid>
                <Grid item xs={8} data-testid="user-id">{siteBionicsApplication.me.id}</Grid>
                <Grid item xs={4}>ToS Version:</Grid>
                <Grid item xs={8} data-testid="tos-version">{siteBionicsApplication.me.tosVersion}</Grid>
                <Grid item xs={4}>ToS Date:</Grid>
                <Grid item xs={8} data-testid="tos-date">{siteBionicsApplication.me.tosDate?.toString()}</Grid>
                <Grid item xs={12}>MSAL Token:</Grid>    

              
            <>
                    {msalToken ?                                     
                        <Grid item xs={12}>
                            <div style={{ wordBreak: "break-all"}}>
                                {msalToken}
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Button onClick={RequestMsalToken}>Request Msal Token</Button>
                        </Grid>
                    }
                    </>

                <Grid item xs={12}>Access Token:</Grid>                    
                {accessToken ?                                     
                    <Grid item xs={12}>
                        <div style={{ wordBreak: "break-all"}}>
                            {accessToken}
                        </div>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Button onClick={RequestAccessToken}>Request Access Token (sbjwt)</Button>
                    </Grid>
                }


                <Grid item xs={12}>SBJWT Test:</Grid>   
                {siteBionicsApplication.isSystemAdministrator && 
                    <Grid item xs={12}>
                    <Button onClick={RequestUser}>SBJWT Test</Button>
                    </Grid>
                }
            </Grid>
          
        </Box>
    );
};

export default Profile;




