import { Vector3 } from "@babylonjs/core";
import { dotNetTicksToDate } from "../util/Time";



export default class TrackedObjectDataPoint {

    time: Date;
    position: Vector3;

    constructor(time: Date, postion: Vector3) {
        this.time = time;
        this.position = postion;
    }
}