import { action, makeObservable, observable } from "mobx";

export default class PageState {
  @observable state: Map<string, Map<string, any>> = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  setPageState(page: string, key: string, value: any) {
    if (!this.state.has(page)) {
      this.state.set(page, new Map());
    }
    this.state.get(page)!.set(key, value);
  }

  getPageState(page: string, key: string, defaultValue: any) {
    return this.state.get(page)?.get(key) ?? defaultValue;
  }
}
