import { FunctionComponent, useEffect, useRef, useState } from "react";
import { IconButton, Select, MenuItem, Button, FormControl, InputLabel, FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { convertTimezoneOnly, minutesToLabel } from "../util/Time";
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver';
import SnapshotViewerDialog from "../components/SnapshotViewerDialog";
import { sha224 } from "js-sha256";
import duration, { Duration } from "dayjs/plugin/duration";

dayjs.extend(duration);
dayjs.extend(utc);

interface HeatmapData {
  minutes: number;
  days: Array<{ date: Dayjs, day: string, min: number, avg: number, max: number, count: number }>;
}

export function exportOccupancyDataToCSV2(heatmapData: HeatmapData[], site: Site, fileName: string = 'occupancy_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];
  csvRows.push(headerRow.join(','));

  // Loop through each day (0 to 6)
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    // Loop through each time slot
    heatmapData.forEach(hourData => {
      if (hourData.minutes >= site.openHours[dayIndex * 2] && hourData.minutes <= site.openHours[dayIndex * 2 + 1]) {
        const dayData = hourData.days[dayIndex];
        const row = [`${dayData.date.format('MM/DD/YYYY')} ${minutesToLabel(hourData.minutes, true)}`, dayData.avg];
        csvRows.push(row.join(','));
      }
    });
  }

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}


export function exportOccupancyDataToCSV3(seriesData: any[], site: Site, fileName: string = 'occupancy_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];  
  csvRows.push(headerRow.join(','));

  // Loop through each day (0 to 6)
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    // Loop through the hours in reverse order
    seriesData.reverse().forEach(hourData => {
      if (hourData.data[dayIndex].minutes >= site.openHours[dayIndex * 2] && hourData.data[dayIndex].minutes <= site.openHours[dayIndex * 2 + 1]) {
        const ds = hourData.data[dayIndex].date.format('YYYY-MM-DD') + " " + minutesToLabel(hourData.data[dayIndex].minutes, false);
        const row = [ds, hourData.data[dayIndex].y ? hourData.data[dayIndex].y.toFixed(1).toString() : "0"];
        csvRows.push(row.join(','));
      }
    });
  }
  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}
export function exportOccupancyDataToCSV(seriesData: any[], showAllHours: boolean, showMinMax: boolean, openHours: number[], fileName: string = 'occupancy_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Hour'];
  if (seriesData.length > 0) {
    seriesData[0].data.forEach((dayData: any) => {
      if (showMinMax) {
        headerRow.push(dayData.date.format('MM/DD/YYYY') + ' Min');
        headerRow.push(dayData.date.format('MM/DD/YYYY') + ' Avg');
        headerRow.push(dayData.date.format('MM/DD/YYYY') + ' Max');
      } else {
        headerRow.push(dayData.date.format('MM/DD/YYYY') + ' Avg');
      }
    });
  }
  csvRows.push(headerRow.join(','));

  // Add the data rows
  seriesData.reverse().forEach(hourData => {
    const row = [hourData.name];
    hourData.data.forEach((dayData: any) => {
      if (dayData.y !== null) {
        if (showMinMax) {
          row.push(dayData.min.toString());
          row.push(dayData.y.toFixed(1));
          row.push(dayData.max.toString());
        } else {
          row.push(dayData.y.toFixed(1).toString());
        }
      } else {
        row.push('');
      }
    });
    csvRows.push(row.join(','));
  });

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}

const OccupancyChart: React.FC<{ date: Dayjs, site: Site}> = observer(({date, site}) => {
  const [series, setSeries] = useState<any[]>([]);
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const siteBionicsApplcation = useSiteBionicsApplication();  
  const [taggedObjectCounts, setTaggedObjectCounts] = useState<TaggedObjectCounts[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAllHours, setShowAllHours] = useState<boolean>(false);
  const [showMinMax, setShowMinMax] = useState<boolean>(false);
  const siteDate = convertTimezoneOnly(date.startOf('day'), site.timeZone);
  const [granularity, setGranularity] = useState<number>(60);
  const hasFetchedData = useRef(false);
  const [snapshotViewerDialogOpen, setSnapshotViewerDialogOpen] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | undefined>(undefined);
  const [selectedDuration, setSelectedDuration] = useState<Duration | undefined>(undefined);

  const lastClickRef = useRef({
    time: 0,
    dataPointIndex: null as number | null,
    seriesIndex: null as number | null
  });
  
  const seriesRef = useRef<any | undefined>(undefined);
  
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex, seriesIndex }) => {
          const now = Date.now();
          const doubleClickThreshold = 300; // ms
  
          if (
            lastClickRef.current.dataPointIndex === dataPointIndex &&
            lastClickRef.current.seriesIndex === seriesIndex &&
            now - lastClickRef.current.time < doubleClickThreshold
          ) {
            // Double click detected
            handleDoubleClick(dataPointIndex, seriesIndex);
          }
  
          // Update last click info
          lastClickRef.current = {
            time: now,
            dataPointIndex,
            seriesIndex
          };
        },
      },
    },
    theme: { mode: 'dark' },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.0000001,
              color: '#999999'
            },
            {
              from: 0.0000001,
              to: 5,
              color: '#FF5555'
            },
            {
              from: 5,
              to: 10,
              color: '#FFFF55'
            },
            {
              from: 10,
              to: 300,
              color: '#55FF55'
            }
          ],
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {        
        if (typeof val === 'number') {
          const seriesData = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
          return seriesData.label;
        } else if (val === null) {
          return "";
        } else {
          return val.toString();
        }
      },
      style: {
        colors: ['#fff']
      }
    },
    legend: { show: false },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: []  // This will be dynamically set
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const yValue = series[seriesIndex][dataPointIndex];
  
        // Disable tooltip for null y values
        if (yValue === null || yValue === 0) {
          return ''; // Return an empty string to not display the tooltip
        }
          
        // Default tooltip content if yValue is not null
        return `<div style="padding: 5px;">
              <strong>Average:</strong> ${yValue}<br/>
              <strong>Min:</strong> ${w.config.series[seriesIndex].data[dataPointIndex].min}<br/>
              <strong>Max:</strong> ${w.config.series[seriesIndex].data[dataPointIndex].max}<br/>              
            </div>`;
      }
    }
    
  });

  useEffect(() => {
    if (isLoading || hasFetchedData.current) return;
    setIsLoading(true);
    hasFetchedData.current = true; // Set the flag immediately to prevent multiple fetches
    siteBionicsApplcation.service.fetchTaggedObjectCountsAsync(site,
      siteDate, siteDate.add(1, 'week'), "00:15", "").then((taggedObjectCounts) => {
        setTaggedObjectCounts(taggedObjectCounts);        
        setIsLoading(false);
      }).catch(() => {
        hasFetchedData.current = false; // Reset the flag if the fetch fails
        setIsLoading(false);
      });
  }, [date]);

  useEffect(() => {
    const data = taggedObjectCounts.reduce<HeatmapData[]>((acc : HeatmapData[], toc: TaggedObjectCounts) => {

      const minutesInDay = dayjs(toc.time).hour() * 60 + dayjs(toc.time).minute();
      const minutes = Math.floor(minutesInDay / granularity) * granularity;

      const day = dayjs(toc.time).format('ddd M/D');
      const date = dayjs(toc.time).startOf('day');
      
      let hourData = acc.find(h => h.minutes === minutes);
      if (!hourData) {
        hourData = { minutes: minutes, days: [] };
        acc.push(hourData);
      }

      let dayData = hourData.days.find(d => d.day === day);
      if (!dayData) {
        dayData = { date, day, min: toc.min, avg: toc.avg, max: toc.max, count: 1 };
        hourData.days.push(dayData);
      } else {
        dayData.min = Math.min(dayData.min, toc.min);
        dayData.max = Math.max(dayData.max, toc.max);
        dayData.avg = (dayData.avg * dayData.count + toc.avg) / (dayData.count + 1);
        dayData.count += 1;
      }

      return acc;
    }, []);

    setHeatmapData(data);

    const openHours = site.openHours;
    let minOpenTime = 5000;
    let maxCloseTime = 0;
    for (let i: number = 0; i < 7; i++) {
      minOpenTime = Math.min(minOpenTime, openHours[i]);
      maxCloseTime = Math.max(maxCloseTime, openHours[i + 1]);
    }    
    const seriesData = data.filter((hourData) => {
      const minutes = hourData.minutes;
      return (showAllHours || (minutes >= minOpenTime && minutes < maxCloseTime));
    }).map((hourData) => {

      const minutes = hourData.minutes;
      return {
        name: minutesToLabel(minutes, false),
        data: hourData.days.map((dayData) => {
          const dayOfWeek = dayData.date.day();
          const open = openHours ? openHours[dayOfWeek * 2] : 0;
          const close = openHours ? openHours[dayOfWeek * 2 + 1] : 1440;
          if (showAllHours || (minutes >= open && minutes <= close)) {
            return {
              x: dayData.date.format('ddd M/D'),
              y: dayData.avg, // Color based on avg
              min: dayData.min,
              max: dayData.max,
              label: showMinMax ? dayData.min + " / " + dayData.avg.toFixed(1) + " / " + dayData.max : dayData.avg.toFixed(1),
              date: dayData.date,
              minutes: minutes
            }
          } else {
            return {
              x: dayData.date.format('ddd M/D'),
              y: null,
              min: null,
              max: null,
              label: null,
              date: dayData.date,
              minutes: minutes
            }
          }
        })
      }
    }).reverse(); // Reverse the order of the hours, but not the days

    setSeries(seriesData);
    seriesRef.current = seriesData;
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data[0]?.days.map((day: any) => day.date.format('ddd M/D')) || []  // Keep the original x-axis categories
      }
    }));
  }, [date, taggedObjectCounts, showAllHours, showMinMax, granularity]);


  function handleDoubleClick(dataPointIndex: number, seriesIndex: number): void {
    let clickedData = seriesRef.current[seriesIndex].data[dataPointIndex];
    let date = dayjs(clickedData.date).hour(clickedData.minutes / 60).minute(clickedData.minutes % 60);
    let duration = dayjs.duration({minutes: granularity} );
    setSelectedDuration(duration);
    setSelectedStartTime(date);
    setSnapshotViewerDialogOpen(true);
    console.log(date.utc());
  };


  const chartHeight = series.length * 30 + 100;

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (series.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }   

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      <Box component="div" style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
          
        <FormControlLabel label="Show All Hours"
          control={
            <Checkbox checked={showAllHours} onChange={() => setShowAllHours(prev => !prev)}/>
          }            
        />
        <FormControlLabel label="Show Min/Max"
          control={
            <Checkbox checked={showMinMax} onChange={() => setShowMinMax(prev => !prev)}/>
          }            
        />
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="granularity-label">Granularity</InputLabel>
          <Select
            labelId="granularity-label"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value as number)}
            label="Granularity"
          >
            <MenuItem value={60}>By Hour</MenuItem>
            <MenuItem value={30}>By 30 Minutes</MenuItem>
            <MenuItem value={15}>By 15 Minutes</MenuItem>
          </Select>
        </FormControl>            
        <IconButton
          color="primary"
          onClick={() => exportOccupancyDataToCSV2(heatmapData, site)}
          sx={{ marginLeft: 2 }}
        >
          <DownloadIcon />
        </IconButton>
      </Box>

      <Box component="div" display="flex" width="100%" overflow="auto">
        <Box component="div" sx={{ minHeight: chartHeight, width: '100%' }}>
          <ReactApexChart options={options} series={series} type="heatmap" height={chartHeight } width="100%" />
          
          {selectedDuration && selectedStartTime && (
            <SnapshotViewerDialog 
                    open={snapshotViewerDialogOpen}
                    onClose={()=>{setSnapshotViewerDialogOpen(false);}}
                    startTime={selectedStartTime}
                    duration={selectedDuration}
                />
          )}
        </Box>      
      </Box>
    </Box>
  );
});

export default OccupancyChart;
