import React, { useState } from "react";
import { MenuItem, Select, FormControl, InputLabel, Box, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react";

type ColorPickerProps = {
  colors: string[]; // List of predefined colors in hex format
  value: string; // Currently selected color
  onChange: (color: string) => void; // Callback when color changes
};

const ColorPicker: React.FC<ColorPickerProps> = observer(({ colors, value, onChange }) => {

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newColor = event.target.value;
    onChange(newColor);
  };

  return (
    <FormControl fullWidth sx={{marginTop: 2}}>
      <InputLabel id="color-picker-label">Color</InputLabel>
      <Select
        labelId="color-picker-label"
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box component="span"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Box component="span"
              sx={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                backgroundColor: selected as string,
              }}
            />
            {selected}
          </Box>
        )}
      >
        {colors.map((color) => (
          <MenuItem key={color} value={color}>
            <Box component="span"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <Box component="span"
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: color,
                }}
              />
              {color}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default ColorPicker;
