import React, { useEffect, useState } from 'react';
import GeneralCard from './GeneralCard';
import FullScreen from '@mui/icons-material/Fullscreen';
import Account from '../models/Account';
import Site from '../models/Site';
import { observer } from "mobx-react";
import { Typography, Box } from '@mui/material';
import CameraCarousel from '../components/CameraCarousel';
import { useLocation } from 'react-router-dom';

interface VideoCardProps {
  account: Account;
  site: Site;
}

const VideoCard: React.FC<VideoCardProps> = observer(({ account, site }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);  

  useEffect(() => {
    if (!site.cameras) {
      setLoading(true);
      site.loadCamerasAsync();
    } else {
      setLoading(false);
    }
  }, [site.cameras]);

  return (
    <GeneralCard title="View Video"
      icon={<FullScreen style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}
      moreLink={`/accounts/${account.accountId}/sites/${site.siteId}/video`}>

      <Box component="div" display="flex" flexDirection="column" sx={{ gap: "10px" }}>
        {loading && <Typography>Loading...</Typography>}
        {!loading && site.cameras && site.cameras.length > 0 &&
          <CameraCarousel
            cameras={site.cameras}
            accountId={account.accountId}
            siteId={site.siteId}
            small={true}
            showFullScreenButton={true}            
          />
        }
        {!loading && (!site.cameras || site.cameras.length === 0) &&
          <Typography>No cameras</Typography>
        }
      </Box>
    </GeneralCard>
  );
});

export default VideoCard;