import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { VideoPlayer } from "../components/VideoPlayer";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import TitleToolbar from "../components/TitleToolbar";
import { FragmentUploadPriority } from "../models/FragmentUploadPriority";
import ReactGA from "react-ga4";
import TimeSpanShortControl from "../components/TimeSpanShortControl";
import useQueryState from "../hooks/useQueryState";


const Video: FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const site = useSite();
  const breadcrumbs = useSitePageBreadcrumbs("Video");
  const [isFetchingPlayList, setIsFetchingPlayList] = useState<boolean>(false);
  const [playListUrl, setPlayListUrl] = useState<string>("");
  const [duration, setDuration] = useQueryState("video" + site.siteId, siteNavigator.pageState, "duration", 60, (value) => value.toString(), (value) => parseInt(value));
  const [startTime, setStartTime] = useQueryState<Dayjs>("video" + site.siteId, siteNavigator.pageState, "startTime", dayjs(), (value) => value.toISOString(), (value) => dayjs(value));
  const [cameraId, setCameraId] = useQueryState<string>("video" + site.siteId, siteNavigator.pageState, "cameraId", "", (value) => value, value => value);  

  const [imageUrl, setImageUrl] = useState<string>("");
  

  let [searchParams, setSearchParams] = useSearchParams();

  function runQuery(): void {
    ReactGA.event("video_play", {
      accountId: siteNavigator.site.account.accountId,
      siteId: siteNavigator.site.siteId,
      cameraId: cameraId,
      startTime: startTime!.toISOString(),
      endTime: startTime!.add(duration, 'second')
    });

    setIsFetchingPlayList(true);
    setPlayListUrl("");
    siteBionicsApplcation.service.postUploadVideoRequest(
      siteNavigator.site.account.accountId, siteNavigator.site.siteId, cameraId,
      FragmentUploadPriority.UserHigh, startTime!, startTime!.add(duration, 'second')
    ).then((startTime) => {
      if (startTime) {
        console.log("VideoStartTime: ", startTime);
        siteBionicsApplcation.service.videoPlayListUrl(
          siteNavigator.site.account.accountId, siteNavigator.site.siteId, cameraId,
          startTime!, startTime!.add(duration, 'second')).then((url) => {
            siteNavigator.setVideoStartTime(startTime);       
            setPlayListUrl(url);
            setIsFetchingPlayList(false);
          })
      } else {
        siteBionicsApplcation.setLastError("Video not available", "error");
        setIsFetchingPlayList(false)
      }
    });
  }
  
  useEffect(() => {
    setImageUrl("");
    setPlayListUrl("");
    if (cameraId != "") {
      siteBionicsApplcation.service.fetchCameraImageUrl(site.account.accountId, site.siteId, cameraId).then((url) => {
        setImageUrl(url);
      });
      
    }
  }, [cameraId])

  useEffect(() => {
    if (!site.hasSiteCapability("ViewSite")) return;

    siteNavigator.site.loadCamerasAsync().then(() => {
      if (!cameraId && siteNavigator.site.cameras && siteNavigator.site.cameras.length > 0) {
        setCameraId(siteNavigator.site.cameras[0].cameraId, false);
      }
    });
  }, []);

  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" overflow="hidden" display="flex" height="100%" flexDirection="column">
          <BreadcrumbBar breadcrumbs={breadcrumbs} />

          <TitleToolbar>
            <>
              <FormControl sx={{marginY: "20px"}}>
                <InputLabel id="camera-list-label">Camera</InputLabel>
                <Select
                  labelId="camera-list-label"
                  id="camera-list"
                  sx={{width: "100pt"}}
                  value={cameraId}
                  label="Camera"            
                onChange={(event) => {
                  console.log(event.target.value);
                    setCameraId(event.target.value, false);
                  }}>
                  {siteNavigator.site.cameras?.map((c) => (
                    <MenuItem key={c.cameraId} value={c.cameraId}>{c.cameraName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TimeSpanShortControl sx={{marginY: "20px"} }
                initialStartDate={startTime}
                initialDuration={duration}
                onChange={(from: Dayjs, duration: number, to: Dayjs) => {
                  setDuration(duration, false);
                  setStartTime(from, false);
                }}
              />
            <Button size="small" onClick={() => {
              setDuration(duration, true);
              setStartTime(startTime, true);
              setCameraId(cameraId, true);
              runQuery();
              }}
            >
              Play
            </Button>
            </>
          </TitleToolbar>

          <Box component="div" display="flex" flexGrow="1" overflow="hidden">
            {isFetchingPlayList && (
              <LinearProgress sx={{ width: '100%' }} />
            )}
            {!isFetchingPlayList && playListUrl && (
              <VideoPlayer
                startTime={siteNavigator.videoStartTime} 
                fluid={false}
                hlsSrc={playListUrl}
                posterSrc=""
              />
            )}
            {!isFetchingPlayList && !playListUrl && 
              <>
                <Box component="div" display="flex" flexDirection="column" flexGrow="1" justifyContent="center" alignItems="center">
                  <h2>Enter date/time range and hit play</h2>
                  {imageUrl !== "" && 
                    <img src={imageUrl} alt={"Camera " + cameraId} style={{ maxWidth: '25%', maxHeight: '25%', objectFit: 'contain' }} />
                  }

                </Box>
              </>
            }
          </Box>
      </Box>
    </LocalizationProvider>
  )
})

export default Video;