import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Color3, Color4, LinesMesh, MeshBuilder, Vector3 } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Tracklet, { TrackletType } from "../models/Tracklet";
import SiteNavigator from "../models/SiteNavigator";
import TrackedObject from "../models/TrackedObject";

const TrackletLayer: FunctionComponent<{
  tracklet: Tracklet,
  siteNavigator: SiteNavigator,
  selectTrackedObject: (trackedObject: TrackedObject) => void
}> = observer(({ tracklet, siteNavigator, selectTrackedObject }) => {

  const siteBionicsApplcation = useSiteBionicsApplication();
  const scene = useScene();
  const [currentPosition, setCurrentPosition] = useState<Vector3 | undefined>(undefined);

  useEffect(() => {
    if (siteNavigator.currentTime !== undefined) {
      let closestDataPoint = tracklet.findClosestDataPoint(siteNavigator.currentTime.toDate());
      if (closestDataPoint) {
        let pos = new Vector3(closestDataPoint.position.x, closestDataPoint.position.y, closestDataPoint.position.z);
        setCurrentPosition(pos);
      } else {
        setCurrentPosition(undefined);
      }
    }
    else {
      setCurrentPosition(undefined);
    }
  }, [tracklet, siteNavigator.currentTime]);

  const floorOffset = new Vector3(0, 0.25, 0);

  // current position line
  useEffect(() => {

    if (currentPosition === undefined) return;
    
    var allLines: LinesMesh[] = [];

    //draw the vertical line
    const points = [new Vector3(-currentPosition.x, currentPosition.y, currentPosition.z), new Vector3(-currentPosition.x, currentPosition.y + 0.5, currentPosition.z)]
    const color3 = tracklet.camera?.color ?? Color3.Blue();
    const color4 = new Color4(color3.r, color3.g, color3.b, 1)
    const colors = points.map(p => color4)
    const lines = MeshBuilder.CreateLines("lines", {points, colors: colors, updatable: false}, scene);
    allLines.push(lines);

    const positionLH = new Vector3(-currentPosition.x, currentPosition.y, currentPosition.z);
    let cameraPositionLH = siteNavigator.currentScanAreaLayout?.cameras.find(c => c.cameraId === tracklet.camera?.cameraId)?.pose.position.asLHVector3;
    if (cameraPositionLH !== undefined) {
      // draw the line from camera through the point
      const direction = positionLH.subtract(cameraPositionLH).normalize().scale(3);
      const directionFlat = new Vector3(direction.x, direction.y, direction.z);
      const pointA = positionLH.subtract(directionFlat);
      //const pointB = positionLH.add(directionFlat);
      const pointB = positionLH;
      const points = [pointA, pointB];
      const color3 = tracklet.camera?.color ?? Color3.Blue();
      const color4 = new Color4(color3.r, color3.g, color3.b, 1)
      const colors = points.map(p => color4)
      const lines = MeshBuilder.CreateLines("lines", {points, colors: colors, updatable: false}, scene);
      allLines.push(lines);
    }

    // Cleanup function to remove the line from the scene
    return () => {
      allLines.forEach(line => line.dispose());
    };
  }, [currentPosition]);


  // path lines
  // useEffect(() => {
  //   if (!scene ||
  //     tracklet.dataPoints.length === 0 ||
  //     (tracklet !== siteNavigator.currentTracklet && tracklet.trackedObject !== siteNavigator.currentTrackedObject)
  //   ) return;

  //   var allLines: LinesMesh[] = [];

  //   // Define the points and colors for the line
  //   const points = tracklet.ToPoints(new Vector3(0, 0.25, 0));
  //   const color3 = tracklet.camera?.color ?? Color3.Blue();
  //   const color4 = new Color4(color3.r, color3.g, color3.b, 1)
  //   const colors = points.map(p => color4)
  //   // Create the line
  //   const lines = MeshBuilder.CreateLines("lines", {
  //     points,
  //     colors: colors,
  //     updatable: false
  //   }, scene);

  //   allLines.push(lines);

  //   // Cleanup function to remove the line from the scene
  //   return () => {
  //     allLines.forEach(line => line.dispose());
  //   };
  // }, [scene, tracklet, siteNavigator.currentTrackedObject, siteNavigator.currentTracklet]);

  const color = tracklet.camera === undefined || (tracklet && siteNavigator.currentTracklet && tracklet === siteNavigator.currentTracklet) ? Color3.Purple() : tracklet.camera!.color!;

  return (
    <>
      {currentPosition &&
        <>

          {tracklet.trackletType !== TrackletType.Fused &&
            <>
              <sphere name={`${tracklet.trackletId}-sphere`} diameter={0.2} position={new Vector3(-currentPosition.x, currentPosition.y + 0.5, currentPosition.z)}>
                <standardMaterial name={`${tracklet.trackletId}-mat`} diffuseColor={color} />
              </sphere>
            </>
          }

        </>
      }

    </>
  )
})

export default TrackletLayer;


